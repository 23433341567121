// update the user 
export const userDispatch = (type, data) =>{
    if (type === 'user'){
        return (dispatch) =>{
            dispatch({
            type: 'user',
            data: data   
            })
        }
    }else if (type ==='permissions'){
        return (dispatch) =>{
            dispatch({
            type: 'permissions',
            data: data   
            })
        }
    }
}


export const rptUserDispatch = (type, data) =>{

   if(type ==='rpt_permissions'){
        return (dispatch) =>{
            dispatch({
            type: 'rpt_permissions',
            data: data   
            })
        }
    }
}

export const mdsUserDispatch = (type, data) =>{

    if(type ==='mds_permissions'){
         return (dispatch) =>{
             dispatch({
             type: 'mds_permissions',
             data: data   
             })
         }
     }
 }